import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './card.module.scss';

const Card = ({ content }) => (
  <Link className={styles.container} to={content.slug}>
    <article>
      <Img
        className={styles.image}
        fluid={content.image}
        alt={content.imageAlt}
      />
      <div className={styles.content}>
        <h2 className={styles.title}>{content.title}</h2>
        <p className={styles.excerpt}>{content.intro}</p>
        <p className={styles.readMore}>Seguir leyendo »</p>
      </div>
    </article>
  </Link>
);

Card.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    intro: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.isRequired,
    imageAlt: PropTypes.string,
  }).isRequired,
};

export default Card;
