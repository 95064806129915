import React from 'react';
import PropTypes from 'prop-types';

import Prelude from '../molecules/prelude';
import Card from '../molecules/card';
import styles from './latests-case-studies.module.scss';

const LatestsCaseStudies = ({ caseStudies }) => (
  <section className={styles.container}>
    <Prelude
      content={{
        title: 'Últimos casos de éxito',
      }}
    />
    <div className={styles.cards}>
      {caseStudies.map(({ node }) => (
        <Card
          key={node.id}
          content={{
            slug: `/trabajos/${node.frontmatter.slug.replace('/', '')}/`,
            title: node.frontmatter.title,
            intro: node.frontmatter.intro,
            image: node.frontmatter.featuredImage.childImageSharp.fluid,
            imageAlt: node.frontmatter.featuredImageAlt,
          }}
        />
      ))}
    </div>
  </section>
);

LatestsCaseStudies.propTypes = {
  caseStudies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LatestsCaseStudies;
