import React from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import './body-case-study.scss';

const CaseStudyBody = ({ content }) => (
  <div className="container">
    <MDXRenderer>{content.body}</MDXRenderer>
  </div>
);

CaseStudyBody.propTypes = {
  content: PropTypes.shape({
    body: PropTypes.string.isRequired,
  }).isRequired,
};

export default CaseStudyBody;
