import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './hero-case-study.module.scss';

const HeroBanner = ({ content }) => (
  <header className={styles.container}>
    <div className={styles.backLink}>
      <Link to="/trabajos/">Volver a Trabajos</Link>
    </div>
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>{content.title}</h1>
        <h2 className={styles.pretitle}>Caso de éxito</h2>
        <p className={styles.description}>{content.description}</p>
      </div>
      <div className={styles.image}>
        <Img fluid={content.image} alt={content.imageAlt} />
      </div>
    </div>
  </header>
);

HeroBanner.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    imageAlt: PropTypes.string,
  }).isRequired,
};

export default HeroBanner;
