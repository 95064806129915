import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/molecules/seo';
import HeroCaseStudy from '../components/molecules/hero-case-study';
import BodyCaseStudy from '../components/organisms/body-case-study';
import LatestsCaseStudies from '../components/organisms/latests-case-studies';

const CaseStudy = ({ data }) => (
  <>
    <SEO
      title={data.currentCaseStudy.frontmatter.titleTag}
      description={data.currentCaseStudy.frontmatter.metaDescription}
    />
    <article>
      <HeroCaseStudy
        content={{
          title: data.currentCaseStudy.frontmatter.title,
          description: data.currentCaseStudy.frontmatter.intro,
          image:
            data.currentCaseStudy.frontmatter.featuredImage.childImageSharp
              .fluid,
          imageAlt: data.currentCaseStudy.frontmatter.featuredImageAlt,
        }}
      />
      <BodyCaseStudy content={{ body: data.currentCaseStudy.body }} />
      <LatestsCaseStudies caseStudies={data.latestsCaseStudies.edges} />
    </article>
  </>
);

CaseStudy.propTypes = {
  data: PropTypes.shape({
    currentCaseStudy: PropTypes.object,
    latestsCaseStudies: PropTypes.object,
  }).isRequired,
};

export default CaseStudy;

export const query = graphql`
  query($slug: String!) {
    currentCaseStudy: mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        titleTag
        metaDescription
        slug
        title
        intro
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 446, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageAlt
      }
    }
    latestsCaseStudies: allMdx(
      limit: 2
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            intro
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 160, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
  }
`;
